(function () {
  'use strict';

  angular.module('services.product').service('ProductService', ProductService);

  function ProductService($http, config) {
    let selectedProduct = null;
    const ENVIO = 'ENVIO';
    const RETIRO = 'RETIRO';

    return {
      getCategoriesSections: getCategoriesSections,
      getProductDetails: getProductDetails,
      getProductsByAddress: getProductsByAddress,
      getProductsByFilter: getProductsByFilter,
      getProductsByStore: getProductsByStore,
      getRandomProducts: getRandomProducts,
      getSelectedProduct: getSelectedProduct,
      setSelectedProduct: setSelectedProduct,
      getNewMenuCategories: getNewMenuCategories,
      getProductsCarrusel: getProductsCarrusel,
      getProductsAntojo: getProductsAntojo
    };

    function getCategoriesSections() {
      return $http.get(`${config.URL_API}/dishes/categories_sections`);
    }

    function getProductDetails(dishId, storeId, kind) {
      return $http.get(`${config.URL_API}/dishes/${dishId}`, {
        params: {
          store_id: storeId,
          tipo_distribucion: kind === 0 ? ENVIO : RETIRO,
        },
      });
    }

    function getProductsByAddress(addressId, categoryId, sectionId) {
      return $http.get(`${config.URL_API}/dishes/addresses/${addressId}`, {
        params: {
          categorie_id: categoryId,
          section_ids: sectionId,
        },
      });
    }

    function getProductsByFilter(id, text, kind) {
      return $http.get(`${config.URL_API}/dishes/stores/${id}/filtros`, {
        params: {
          texto: text,
          kind: kind,
        },
      });
    }

    function getProductsByStore(storeId, kind, categoryId) {
      return $http.get(`${config.URL_API}/dishes/v2/stores/${storeId}`, {
        params: {
          tipoDistribucion: kind === 0 ? ENVIO : RETIRO,
          categoryId: categoryId,
        },
      });
    }

    function getRandomProducts(store, kind, quantity) {
      return $http.get(`${config.URL_API}/dishes/stores/${store}/random`, {
        params: {
          kind: kind,
          quantity: quantity,
        },
      });
    }

    function getProductsCarrusel(storeId, kind) {
      return $http.get(`${config.URL_API}/dishes/v2/stores/${storeId}/carrusel`, {
        params: {
          tipoDistribucion: kind === 0 ? ENVIO : RETIRO,
        },
      });
    }

    function getProductsAntojo(storeId, kind) {
      return $http.get(`${config.URL_API}/dishes/v2/stores/${storeId}/antojo`, {
        params: {
          tipoDistribucion: kind === 0 ? ENVIO : RETIRO,
        },
      });
    }

    /**
     * Obtener el producto seleccionado desde el catálogo
     * @returns {object|null}
     */
    function getSelectedProduct() {
      return selectedProduct;
    }

    /**
     * Establecer el producto seleccionado al hacerle click desde el catálogo
     * @param {object} product
     */
    function setSelectedProduct(product) {
      selectedProduct = product;
    }

    // nuevo menu categorias + subcategorias
    function getNewMenuCategories() {
      return $http.get(`${config.URL_API}/dishes/v2/categorias`);
    }
  }
})();
