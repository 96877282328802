(function () {
  'use strict';

  angular.module('services.forkCard').service('ForkCardService', ForkCardService);

  function ForkCardService($http, config) {

    return {
      getHistoryCard : getHistoryCard,
      getCoupons: getCoupons
    };

    function getHistoryCard(user_id) {
      //historial_ventas/usuario/200591
      return $http.get(`${config.URL_API}/historial_ventas/usuario/${user_id}`);
    }

    function getCoupons(token) {
      return $http.get(`${config.URL_API}/discount/coupons/tarjeta-fork`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
      });
    }
  
  }
})();
