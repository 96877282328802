(function () {
  'use strict';

  angular.module('shared.footer').component('footerComponent', {
    templateUrl: 'shared/footer/footer.template.html',
    controller: FooterController,
    controllerAs: 'vm',
  });

  function FooterController(config, NavigationService, $location, $rootScope) {
    var vm = this;

    vm.navUrls = NavigationService.getExternalUrlsF();
    vm.social = NavigationService.getSocialUrls();

    vm.paymentMethods = [
      {
        name: 'WebpayPlus',
        src: 'assets/img/WebpayPlus.svg',
        width: '45',
        height: '25',
      },
    ];

    vm.footerType = '';

    $rootScope.$on('$stateChangeStart', stateChangeStart);

    //Muestra un tipo de footer según la ruta donde esté
    function stateChangeStart(event, toState) {
      if (toState.name === 'checkout') {
        vm.footerType = 'checkout';
      } else {
        vm.footerType = 'general';
      }
    }
  }
})();
