(function () {
  'use strict';

  angular
    .module('pages.userProfile.forkCard').filter('chileanCurrency', function () {
      return function (input) {
          if (!input) return '$0';

          // Convertir a número y asegurar formato correcto
          const number = parseFloat(input);
          if (isNaN(number)) return input;

          // Formatear el número con separador de miles como punto
          const formatted = number.toLocaleString('es-CL');

          // Agregar símbolo de peso chileno
          return `$${formatted}`;
      };
  })
    .controller('ForkCardController', ForkCardController);

  function ForkCardController(
    $anchorScroll,
    $rootScope,
    $scope,
    AlertService,
    AnalyticsService,
    GlobalService,
    ForkCardService,
    CookiesService
  ) {
    var vm = this;
    vm.$onInit = onInit;
    $scope.global = GlobalService;
    const authToken = CookiesService.getAuthToken();

    $scope.timbresActuales      = 0;
    $scope.timbresRequeridos    = 5;
    $scope.platosDisponibles    = 0;
    $scope.platosCanjeados      = 0;
    $scope.ahorroTotal          = 0;

    $scope.historialTimbres = [
    ];

    $scope.historialTarjetaFork = [
    ];

    $scope.sumarUnMes = function(fecha) {
      if (!fecha) return '';
      // valida la fecha y trns a tipo Date
      let fechaOriginal = new Date(fecha);
      if (isNaN(fechaOriginal)) return fecha; // not a number
  
      fechaOriginal.setMonth(fechaOriginal.getMonth() + 1); // Sumar 1 mes
      return fechaOriginal;
    };

    function onInit() {
      console.log("EN FORK CARD", $scope.global);

      if (!$scope.global.user || !$scope.global.user.auth_token) {
        console.error("Token no disponible, no se puede hacer la petición");
        return;
      }

      ForkCardService.getHistoryCard($scope.global.user.id).then((value) => {
        console.log("value",value);
        $scope.timbresActuales      = value.data.response.acumulados;
        $scope.platosDisponibles    = value.data.response.disponibles;
        $scope.platosCanjeados      = value.data.response.canjeados;
        $scope.historialTimbres     = value.data.response.ventas;
        $scope.ahorroTotal          = value.data.response.ahorrado;

      })

      ForkCardService.getCoupons(authToken).then((value) => {
        console.log("value cupon", value);
        $scope.historialTarjetaFork = value.data;
      });
    }
  }
})();
