(function () {
  'use strict';

  angular.module('services.global').service('GlobalService', GlobalService);

  function GlobalService() {
    let global = {
      activeCategory: null,
      activeStore: null,
      addresses: null,
      cart: null,
      communes: [],
      configDelivery: {
        kind: null,
        store: null,
        address: null,
        paymentMethod: null,
        default: false,
      },
      creditCards: null,
      footerMessage: null,
      orders: null,
      paymentMethods: [],
      productCarousel: [],
      productAntojo: [],
      productCategories: [],
      showFooterMessage: false,
      user: null,
      userConfig: null,
      infoAnalytics: null,
      pasaxAnalytics: null,
      entraAnalytics: null,
      productos: null,
      menuData: null,
      initialLoadCompleted: false,
      billing: null,
      defaultBilling: null
    };

    Object.seal(global);

    return global;
  }
})();
