(function () {
  'use strict';

  angular.module('services.catalog').service('CatalogService', CatalogService);

  function CatalogService($q, $http, $rootScope, $stateParams, GlobalService, ProductService) {
    return {
      getCatalogOffset: getCatalogOffset,
      updateCatalog: updateCatalog,
    };

    /**
     * Obtiene la distancia en la que se encuentra el catálogo con respecto al punto más alto de la pagina,
     * considerando que hay un navbar, un banner, etc.
     * @returns {number}
     */
    function getCatalogOffset() {
      return 150;
    }

    function updateCatalog() {
      const defer = $q.defer();

      const config = GlobalService.configDelivery;
      const storeId = config.store.id;
      const kind = config.kind;
      let categoryId;

      if ($stateParams.categoryId) {
        categoryId = parseInt($stateParams.categoryId);
      } else if (GlobalService.activeCategory) {
        categoryId = GlobalService.activeCategory.id;
      } else {
        categoryId = 0;
      }

      // Obtener únicamente los productos de la categoría seleccionada
      ProductService.getProductsByStore(storeId, kind, categoryId)
        .then((value) => {
          GlobalService.productCategories = value.data;
          console.info('Lista parcial de productos:', GlobalService.productCategories);
          let menuData = value.data.filter((categoria) => categoria.visible === true);

          // Trae los nombres de las secciones y sus imagenes para el menú
          ProductService.getNewMenuCategories().then(({ data }) => {
            let menuHamburguer = data.response;
            menuData = menuData.map((categoria, i) => {
              categoria.secciones = menuHamburguer[i].secciones;
              categoria.secciones = categoria.secciones.map((seccion, j) => {
                seccion.imagen = menuHamburguer[i].secciones[j].imagen;
                return seccion;
              });

              return {
                id: categoria.id,
                nombre: categoria.nombre,
                secciones: categoria.secciones,
                position: i,
                slugName: categoria.nombre.trim().toLowerCase().split(' ').join('-'),
                visible: categoria.visible,
              };
            });

            return menuData;
          });

          GlobalService.menuData = menuData;

          // Actualizar categoría activa
          if (categoryId) {
            const index = GlobalService.productCategories.findIndex(
              (category) => category.id === categoryId
            );
            GlobalService.productCategories[index].position = index;
            GlobalService.activeCategory = GlobalService.productCategories[index];
          } else {
            value.data[0].position = 0;
            GlobalService.activeCategory = value.data[0];
          }
          console.info('Categoría activa: ', GlobalService.activeCategory);

          // Actualizar tienda activa
          GlobalService.activeStore = config.store;
          console.info('Tienda activa:', config.store);
          $rootScope.$broadcast('catalogUpdated');
          console.info('Broadcast: ¡Se actualizó el catálogo!');
          
          ProductService.getProductsCarrusel(storeId, kind)
            .then((res) => {
              GlobalService.productCarousel = res.data.items;
            })
            .catch((error) => {
              console.error("Error al obtener los productos del carrusel",error);
            })

          ProductService.getProductsAntojo(storeId, kind)
            .then((res) => {
              GlobalService.productAntojo = res.data.items;
              console.log("Porductos Antojo 👌", GlobalService.productAntojo)
            })
            .catch((error) => {
              console.error("Error al obtener los productos del carrusel",error);
            })

          // Obtener el resto de productos
          ProductService.getProductsByStore(storeId, kind)
            .then(({ data: allCategories }) => {
              // Verificar si es que la categoría activa es invisible
              const invisibleCategory = GlobalService.productCategories.find(
                (category) => !category.visible
              );

              if (invisibleCategory) {
                allCategories.unshift(invisibleCategory);
              }

              GlobalService.productCategories = allCategories;
              productTest = allCategories;
              GlobalService.menuData = filtrarCategorias(GlobalService.menuData, GlobalService.productCategories);

              console.info('Lista completa de productos:', GlobalService.productCategories);
              $rootScope.$broadcast('catalogUpdated');
              console.info('Broadcast: ¡Se actualizó el catálogo!');
              GlobalService.initialLoadCompleted = true;
            })
            .catch((reason) => {
              defer.reject(reason);
            });

            

          return defer.resolve();
        })
        .catch((reason) => {
          defer.reject(reason);
        });
      return defer.promise;
    }

    function filtrarCategorias(arrayPrincipal, arrayReferencia) {
      // Crear un mapa para almacenar el id de la sección y si tiene items
      var mapaItems = new Map();
    
      // Llenar el mapa con los ids de las secciones y si tienen items
      arrayReferencia.forEach(function(cat) {
        cat.secciones.forEach(function(sec) {
          mapaItems.set(sec.id, sec.items.length > 0);
        });
      });
    
      // Filtrar las categorías del arrayPrincipal
      return arrayPrincipal.map(function(cat) {
        var seccionesFiltradas = cat.secciones.filter(function(sec) {
          return mapaItems.get(sec.id);
        });
        return Object.assign({}, cat, { secciones: seccionesFiltradas });
      });
    }

    
    
  }
})();
