(function () {
  'use strict';

  angular.module('shared.promoCodeModal').component('promoCodeModalComponent', {
    templateUrl: 'shared/promo-code-modal/promo-code-modal.template.html',
    controller: PromoCodeModalController,
    controllerAs: 'vm',
  });

  function PromoCodeModalController(
    $filter,
    $rootScope,
    $scope,
    $timeout,
    AnalyticsService,
    GlobalService,
    CartService,
    UserService
  ) {
    var vm = this;

    // Variables para saber desde dónde se está llamando el modal
    var CHECKOUT = 'checkout';
    var PROFILE = 'profile';

    vm.state = '';

    // Variable para manejar qué mostrar en la vista
    vm.busy = false;

    vm.promoCode = null;
    vm.promoCodeError = false;
    vm.promoCodeMessage = '';

    // Funciones enlazadas a la plantilla
    vm.closePromoCodeModal = closePromoCodeModal;
    vm.enterCode = enterCode;

    // Funciones elnazadas al scope
    $scope.handleEnterCodeError = handleEnterCodeError;
    $scope.handleEnterCodeSuccess = handleEnterCodeSuccess;

    // Funciones enlazadas al rootScope
    $rootScope.$on('openPromoCodeModal', openPromoCodeModal);

    /**
     * Agregar código de promoción
     */
    function enterCode() {
      if (vm.promoCode) {
        vm.busy = true;
        const promoCode = vm.promoCode.toLowerCase();

        // Intentar agregar el código de usuario, dependiendo de si se está agregando desde el perfil del usuario
        // o del checkout
        if (vm.state === CHECKOUT) {
          CartService.addCode(promoCode)
            .then((value) => {
              const { type, response, message } = value.data;
              if (type === 'ok') {
                GlobalService.cart = response;

                // Actualizar créditos, si es que el usuario está registrado
                if (GlobalService.user) {
                  GlobalService.user.unlimited_credits = response.alcancia.total;
                }

                $scope.handleEnterCodeSuccess(promoCode, message);
              } else {
                $scope.handleEnterCodeError(value);
              }
            })
            .catch((reason) => $scope.handleEnterCodeError(reason));
        } else {
          UserService.addCode(promoCode, false)
            .then((value) => {
              const { credit_increase, unlimited_credits } = value.data;

              // Actualizar créditos, si es que el usuario está registrado
              if (GlobalService.user) {
                GlobalService.user.unlimited_credits = unlimited_credits;
              }

              $scope.handleEnterCodeSuccess(promoCode, credit_increase);
            })
            .catch((reason) => $scope.handleEnterCodeError(reason));
        }
      }
    }

    /**
     * Abrir modal de códigos de promoción
     */
    function openPromoCodeModal(event, opt) {
      vm.state = opt.source;
      vm.busy = false;
      vm.promoCode = null;
      vm.promoCodeError = false;
      vm.promoCodeMessage = '';
    
      // Limpiar estado del formulario
      $scope.form.$setPristine();
      $scope.form.$setUntouched();
    
      $('#promo-code-modal-component').modal('show');
    
      // Se enfoca solo cuando el modal ya este completamente visible (shown.bs.modal)
      $('#promo-code-modal-component').on('shown.bs.modal', function () {
        document.getElementById('promoCodeInput').focus();
      });
    
      AnalyticsService.initPromoCode(opt.source);
    }

    /**
     * Canal de comunicación en el que el modal de códigos de promoción pueda hacerle saber a otros
     * componentes qué es lo que está ocurriendo
     * @param {boolean} success La operación fue un éxito
     * @param {string} message Mensaje de éxito
     */
    function closePromoCodeModal(success, message) {
      $('#promo-code-modal-component').modal('hide');

      if (vm.state === PROFILE) {
        $rootScope.$broadcast('updatePromos');
        console.info('Broadcast: ¡Actualizar promociones!');
        $rootScope.$broadcast('promoCodeMessage', { message: message });
        console.info('Broadcast: ¡Mostrar mensaje del código de promoción!');
      } else if (vm.state === CHECKOUT && success) {
        $rootScope.$broadcast('promoCodeMessage', { message: message });
        console.info('Broadcast: ¡Mostrar mensaje del código de promoción!');
      }
    }

    /**
     * Manejo de cuando ocurre un error agregando un código de promoción
     * @param reason
     */
    function handleEnterCodeError(reason) {
      vm.promoCodeMessage = reason.data.message;
      vm.promoCodeError = true;
      vm.busy = false;
    }

    /**
     * Manejo de cuando agregar un código de promoción fue un éxito
     * @param promoCode
     * @param promoCodeMessage
     */
    function handleEnterCodeSuccess(promoCode, promoCodeMessage) {
      vm.promoCodeError = false;
      vm.promoCodeMessage = '';

      vm.closePromoCodeModal(true, promoCodeMessage);
      AnalyticsService.addPromoCode(promoCode, promoCodeMessage);
    }
  }
})();
