(function () {
  'use strict';

  angular.module('shared.footerMessage').component('footerMessageComponent', {
    templateUrl: 'shared/footer-message/footer-message.template.html',
    controller: FooterMessageController,
    controllerAs: 'vm',
  });

  function FooterMessageController($scope, contentful, DateService, GlobalService, StoreService) {
    var vm = this;
    $scope.global = GlobalService;

    vm.imageUrl = '';

    vm.$onInit = onInit;
    vm.dismissFooterMessage = dismissFooterMessage;

    $scope.loadData = loadData;
    $scope.checkContenfulMessage = checkContentfulMessage;
    $scope.checkAlertNextDay = checkAlertNextDay;

    function onInit() {
      if ($scope.global.footerMessage === null) {
        $scope.checkContenfulMessage();
      } else if ($scope.global.showFooterMessage) {
        $scope.loadData($scope.global.footerMessage);
      }
    }

    /**
     * Verificar si hay que mostrar el mensaje de delivery del día siguiente
     */
    function checkAlertNextDay() {
      StoreService.getStores().then((value) => {
        const selectedStore = value[0];

        // Para saber si hay delivery para mañana, basta con que cualquier bloque de delivery tenga la propiedad `dia`.
        StoreService.getSchedule(selectedStore.id).then(function (value) {
          var store = value.data;

          if (
            store &&
            store.available_schedules &&
            store.available_schedules.length &&
            store.available_schedules[0].dia
          ) {
            $scope.loadData({
              type: 'info',
              title: '',
              message: {
                fields: { text: 'Ya puedes puedes pedir delivery para recibirlo mañana.' },
              },
            });
          }
        });
      });
    }

    /**
     * Verificar si un mensaje por publicar en el footer configurado desde Contentful
     */
    let hasExecutedNextDayAlert = false; // Variable de control

    function checkContentfulMessage() {
      var index;
      var data;

      contentful.entries('content_type=alert&order=-sys.createdAt').then(function (value) {
        data = value.data;

        // Verificar si hay alertas disponibles en la respuesta
        if (data.total) {
          let alertFound = false; // Bandera para saber si se encontró una alerta en el footer

          data.items.forEach(function (item) {
            if (item.fields && item.fields.showIn && item.fields.showIn.length) {
              index = item.fields.showIn.findIndex(function (component) {
                return component === 'Footer';
              });

              // Si la alerta pertenece al footer
              if (index !== -1) {
                alertFound = true;

                // Verificar si la alerta tiene fechas de inicio y fin definidas
                if (item.fields.begins && item.fields.ends) {
                  DateService.getCurrentTimestamp().then(function (value) {
                    var today = value * 1000;
                    var alertBegins = item.fields.begins;
                    var alertEnds = item.fields.ends;

                    // Verificar si la fecha actual está dentro del rango de la alerta
                    if (moment(today).isAfter(alertBegins) && moment(today).isBefore(alertEnds)) {
                      // Si la alerta está vigente, cargar sus datos en el scope
                      $scope.loadData(item.fields);
                    } else {
                      // Si no está vigente, disparar la alerta del siguiente día
                      triggerNextDayAlert();
                    }
                  });
                } else {
                  // Si no tiene fechas definidas, cargar la alerta directamente
                  $scope.loadData(item.fields);
                }
              }
            }
          });

          // Si ninguna alerta válida fue encontrada, ejecutar el aviso del siguiente día
          if (!alertFound) {
            triggerNextDayAlert();
          }
        } else {
          // Si no hay alertas en la respuesta, ejecutar el aviso del siguiente día
          triggerNextDayAlert();
        }
      });
    }

    // Función que activa la verificación de alertas para el día siguiente si aún no se ha ejecutado
    function triggerNextDayAlert() {
      if (!hasExecutedNextDayAlert) {
        hasExecutedNextDayAlert = true; // Evitar ejecuciones repetidas
        $scope.checkAlertNextDay();
      }
    }


    /**
     * Ocultar el mensaje del footer
     */
    function dismissFooterMessage() {
      GlobalService.showFooterMessage = false;
    }

    /**
     * Cargar la información que se mostrará en el mensaje del footer
     * @param item - Objeto que contiene la información desde contentful
     */
    function loadData(item) {
      if (item.type && (item.title || item.message)) {
        vm.imageUrl = 'assets/img';

        switch (item.type) {
          case 'success':
            vm.imageUrl += '/check-green.svg';
            break;
          case 'warning':
            vm.imageUrl += '/warning.svg';
            break;
          case 'info':
            vm.imageUrl += '/info.svg';
            break;
        }

        vm.type = item.type;
        vm.title = item.title;
        vm.message = item.message ? item.message.fields.text : '';

        GlobalService.footerMessage = item;
        GlobalService.showFooterMessage = true;
      }
    }
  }
})();
